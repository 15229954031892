<template>

  <div class="w100 it-al-col">
    <div class="w90 m-auto item-ali-center mt-2">
      <commonBreadcrumbs class="w-100" :items="items" />
    </div>
    <v-divider class="w-100 m-0"></v-divider>

    <div class="min-h-fit w90 ma-auto mt-2 mb-2 item-ali-center">
      <template v-if="loader">
        <v-progress-linear
            :active="loader"
            color="deep-purple"
            height="4"
            indeterminate
        ></v-progress-linear>
      </template>
      <v-container class="it-sp-bw w-100 m-0" v-if="!loader">
        <v-row v-if="!dialog" class="d-flex flex-wrap min-h-fit">
          <v-card class="min-w49 h-fit width-describe" sm="12" max-width="384">

            <v-card-title v-tooltip="'Titre du film'" class="text-h5 w-100">{{(movie)? movie.title :''}}</v-card-title>
            <v-divider class="w-100 m-0"></v-divider>

            <v-card-text v-tooltip="'Description du film'" class="v-100" v-if="movie && movie.description">
              <div  v-html="movie.description"></div>
            </v-card-text>

            <v-divider class="mb-1 w-100"></v-divider>

            <v-card-title><u>Acteurs</u> : </v-card-title>

            <div class="px-4 mb-2 w-100">
              <v-chip-group selected-class="bg-deep-purple-lighten-2 w-100">
                <v-chip v-for="(item,i) in actors" :key="i">
                  {{(item)? item.first_name: ''}}
                  {{(item)? item.last_name: ''}}
                </v-chip>

              </v-chip-group>
            </div>
            <v-divider class="m-0 w-100"> </v-divider>

            <v-card v-if="movie"
                    class="w-100 ma-auto justify-space-between flex-row it-al-row p-2">
              <div class="m-auto w-fit" style="margin-left: 0.2rem!important;">
                  <v-btn class="w-fit p-2"
                         color="indigo-darken-3"
                         text="Modifier"
                         block
                         v-tooltip="'Modficier ce film'"
                         @click="editMovie(movie)">
                    <v-icon
                        class="me-2 size15"
                        size="small">
                      mdi-pencil
                    </v-icon>Modifier</v-btn>
              </div>
              <div class="m-auto w-fit">
                <v-icon  class="cur-p size5 m-auto"
                         v-tooltip="'Génerer un QrCode pour le film'"
                         style="background: #fef4e4!important;"
                         v-on:click="dialogQRcode=!dialogQRcode">mdi-qrcode-edit</v-icon>
              </div>
            </v-card>
          </v-card>


          <v-card class="d-flex flex-column ma-auto p-1 mt-2" elevation="10">
            <ReviewMovie  :notes="notes" :movie="movie" :actors="actors" :grade_avg="grade_avg" :count="count"  @close="close" @action="getMovieById" />
          </v-card>
        </v-row>
        <AddMovie v-if="dialog" :add-action="false" :movie="movie"  @close="close" @action="getMovieById" />

        <v-dialog
            v-model="dialogQRcode"
            max-width="290"
            v-if="dialogQRcode && movie && movie.id">
          <QRGenerator :add-action="this.addAction"  :source="'movie'"  :cur-code-info="movie.id" :movie="movie"  @close="close"/>
        </v-dialog>
      </v-container>

    </div>
  </div>

</template>
<script>
import CommonCRUDService from "@/services/CommonCRUD.service";
import AddMovie from "@/components/movies/AddMovie";
import ReviewMovie from "@/components/movies/ReviewMovie";
import AlertService from "@/services/alert.service";
import QRGenerator from "@/components/commons/QRGenerator";
import commonBreadcrumbs from "@/components/commons/commonBreadcrumbs";

export default {
  name:"DescribeMovie",
components: {
  AddMovie,
  ReviewMovie,
  QRGenerator,
  commonBreadcrumbs
},
   data() {
    return {
      loader: false,
      movie: null,
      dialog: false,
      grade_avg: null,
      notes: [],
      actors: [],
      count: null,
      allActors:null,
      updateMovie: false,
      editedItem: null,
      dialogGrade: false,
      addAction: false,
      dialogQRcode: false,
      items: [ {
        title: 'Accueil',
        disabled: false,
        href: '/',
      },
        {
          title: 'Films',
          disabled: true,
          href: 'movies/',
        },
        {
          title: 'Details',
          disabled: true,
          href: 'movies/',
        }],
    };
  },
  mounted() {
  },
   watch: {"$route.params.id": {
      handler() {
        this.getMovieById();
      },
      immediate: true,
    },
  },
   computed: {
  },
  created () {
    this.getMovieById();
  },
  methods: {
    async getMovieById() {
      if (this.$route && this.$route.params) {
        const id = this.$route.params.id;
        const url = `movie/${id}/`
        this.loader = true;
        CommonCRUDService.getData(url).then(
            (response) => {
              this.loader = false;
              if (response && response.data && response.data.movie) {
                this.movie = response.data.movie;
                this.actors = response.data.movie.actors;
                if (response.data.movie && response.data.movie.grade_avg){
                  this.grade_avg =response.data.movie.grade_avg.avg;
                  this.notes = response.data.movie.grade_avg.notes;
                  this.count =  response.data.movie.grade_avg.count;
                }
                if (this.items.length > 2 && this.items[2] && this.movie){
                  this.items[2].title = "Details du film : " + this.movie.title;
                  this.items = [... this.items];
                }
              }
            },
            (error) => {
              this.loader = false;
              this.movies = [];
              const errsms = (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();
              AlertService.error(errsms);
            })

      }
    },
    editMovie(item) {
      this.editedIndex = this.actors.indexOf(item)
      this.editedItem = Object.assign({}, item);
      this.dialog = true
    },
    close() {
      this.dialog = false;
      this.dialogGrade = false;
      this.dialogQRcode = false;
    }
  },
  
}
</script>
<style>
.rating-values {
  width: 25px;
}
</style>
