<template>
  <div class="d-flex justify-center mt-auto flex-wrap p-1 w-99">
    <v-col cols="auto font-weight-bold">
      <u><strong>Notation du film</strong></u>
    </v-col>
    <v-col cols="auto">
      <v-btn size="small" color="indigo-darken-3" v-on:click="dialog=!dialog">
        <v-icon
            class="me-2"
            size="small">
          mdi-plus-box
        </v-icon>Ajouter une notation</v-btn>
    </v-col>
  </div>
  <v-divider class="m-0 w-100"></v-divider>

  <div class="d-flex align-center flex-column my-auto w-100 card">
    <div class="text-h6 ma-auto w-fit"> Moyenne:</div>
      <div class="text-h2 w-fit ma-auto" v-tooltip="'Moyenne de notation'">
        <span class="text-h3 ml-n3"> {{grade_avg}}/5</span>
      </div>

    <v-rating
        :model-value="grade_avg"
        color="yellow-darken-3"
        half-increments
    ></v-rating>
  </div>
  <v-card class="v-100 p-2 text-center">
    <div class="v-100 font-weight-bold">Nombre de notation: {{count}}</div>
  </v-card>

  <v-list
      bg-color="transparent"
      class="d-flex flex-column-reverse"
      density="compact"
  >
    <v-list-item v-for="(item,i) in notes" :key="(item)? item.type: i">
      <v-progress-linear
          :model-value="item.count * 15"
          class="mx-n5"
          color="yellow-darken-3"
          height="20"
          rounded
      ></v-progress-linear>

      <template v-slot:prepend>
        <span v-tooltip="'catégorie de notation'">{{ item.type }}</span>
        <v-icon class="mx-3" icon="mdi-star"></v-icon>
      </template>

      <template v-slot:append>
        <div class="rating-values">
          <span class="d-flex justify-end" v-tooltip="'Nombre de notation'"> {{ item.count }} </span>
        </div>
      </template>
    </v-list-item>
  </v-list>

  <v-dialog
      v-model="dialog"
      v-if="movie"
      max-width="600"
  >
   <v-card>
     <v-card-title class="text-center">
       Ajout d'une notation
     </v-card-title>
     <v-row class="p-2">
       <v-col cols="12">
         <v-text-field
             label="Notation (entre 1 et 5) *"
             required
             v-model="grade"
         ></v-text-field>
       </v-col>
       <v-col>
         <div class="text-center">
           <v-rating v-model="grade">
             <template v-slot:item="props">
               <v-icon
                   :color="props.isFilled ? colors[props.index] : 'grey-lighten-1'"
                   size="large"
                   @click="props.onClick"
               >
                 {{ props.isFilled ? 'mdi-star-circle' : 'mdi-star-circle-outline' }}
               </v-icon>
             </template>
           </v-rating>
         </div>
       </v-col>
     </v-row>
     <v-divider></v-divider>
     <div class="card it-sp-bw it-al-row pa-2">
       <v-btn
           color="warning"
           variant="text"
           v-on:click="close">
         Annuler
       </v-btn>
       <v-btn
           color="success"
           class="mr-4 cur-p"
           variant="text"
           v-on:click="saveNotation()"
       >
         Enregistrer
       </v-btn>
     </div>
   </v-card>
  </v-dialog>
</template>
<script>
import CommonCRUDService from "@/services/CommonCRUD.service";
import AlertService from "@/services/alert.service";
export default {
  data() {
    return {
      grade: null,
      description: "",
      dialog:false,
      colors: ['green', 'purple', 'orange', 'indigo', 'red'],
    };
  },
  props: ['actors', 'movie', 'grade_avg', 'notes', 'count'],
  emits: ['close', 'action'],

  methods: {
    close() {
      this.dialog = false;
      this.grade = null;
     // this.$emit('close', true)
    },

    async saveNotation() {
     if (this.movie) {
       try {
         let data = {
           grade: this.grade,
           movie: this.movie.id,
         }
         const url = 'movie/' + this.movie.id + '/review/save';
         await CommonCRUDService.postData(data, url);
         AlertService.success('Votre notation a été prise en compte');
         this.dialog = false;
         this.grade = null;
         this.$emit('action', true)
       } catch (err) {
         console.log(err);
         AlertService.error(err.messages);
       }
     }
    }
  },

  created() {
  },
  computed: {

  },

}
</script>

<style>
.rating-values {
  width: 25px;
}
</style>
