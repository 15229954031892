<template>
  <div class="w80 m-auto item-ali-center" v-if="source !== 'home'">
    <commonBreadcrumbs class="w-100" :items="items" />
  </div>
  <v-divider class="w-100 m-0"></v-divider>
  <div class="min-h-fit w80 ma-auto mt-2 mb-2 item-ali-center it-al-col">
    <v-data-table
        :headers="headers"
        :items="actors"
        :loading="loading"
        hide-default-footer
        :sort-by="[{ key: 'first_name', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-toolbar
            flat>
          <v-toolbar-title>Gestions des acteurs</v-toolbar-title>
          <v-dialog
              v-model="dialog"
              max-width="600px"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                  class="mb-2 w-fit"
                  color="primary"
                  v-bind="props"
              >
                <v-icon>mdi-movie-plus</v-icon>
                Nouveau acteur
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                    >
                      <v-text-field
                          v-model="editedItem.first_name"
                          label="Prénom"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                    >
                      <v-text-field
                          v-model="editedItem.last_name"
                          label="Nom"
                      ></v-text-field>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue-darken-1 red"
                    variant="text"
                    @click="close"
                >
                  Annuler
                </v-btn>
                <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="save"
                >
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="scanner"
              max-width="600px"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                  class="mb-2"
                  color="primary"
                  dark
                  v-bind="props"
              >
                scanner
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <QRCodeReader :source="'actor'" :action="scanner" @close="scanner=!scanner" @actor="gotoActor"/>


              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue-darken-1 red"
                    variant="text"
                    @click="close"
                >
                  Annuler
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Voulez-vous supprimer cet acteur?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">

        <v-icon
            v-tooltip="'Détails de l\'acteur'"
            class="me-2 size15"
            size="small"
            color="blue"
            @click="gotoActor(item.id)"
        >
          mdi-card-account-details-star
        </v-icon>
        <v-icon
            v-tooltip="'Modifier de l\'acteur'"
            class="me-2 size15"
            size="small"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon  class="cur-p size15 m-auto"
                 v-tooltip="'Génerer un qrcode pour  cet acteur'"
                 @click="editQRcode(item)">mdi-qrcode-edit</v-icon>

<!--        <v-icon
            size="small"
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>-->
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center w-100">
      <v-pagination
          v-model="page"
          :length="totalActors"
          @input="getActors"
          rounded="circle"
      ></v-pagination>
    </div>

    <v-dialog
        v-model="dialogQRcode"
        max-width="290"
        v-if="dialogQRcode && editedItem && editedItem.id">
      <QRGenerator :add-action="this.addAction"  :source="'actor'"  :cur-code-info="editedItem.id" :actor="editedItem"  @close="close"/>
    </v-dialog>

  </div>
</template>
<script>
import CommonCRUDService from "@/services/CommonCRUD.service";
import AlertService from "@/services/alert.service";
import commonBreadcrumbs from "@/components/commons/commonBreadcrumbs";
import QRGenerator from "@/components/commons/QRGenerator";
import QRCodeReader from "@/components/commons/QRCodeReader";

export default {
  name:"Actors",
  components :{
    commonBreadcrumbs,
    QRGenerator,
    QRCodeReader
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogQRcode: false,
    headers: [
      {
        title: 'Prénom',
        align: 'start',
        sortable: true,
        key: 'first_name',
      },
      { title: 'Nom', key: 'last_name' },
      { title: 'Nombre de film', key: 'total_movie',  align: 'center'},
      { title: 'Actions', key: 'actions', sortable: false },
    ],
    actors: [],
    editedIndex: -1,
    addAction: true,
    editedItem: {
      first_name: '',
      last_name: '',
      id: null
    },
    defaultItem: {
      first_name: '',
      last_name: ''
    },
    items: [ {
      title: 'Accueil',
      disabled: false,
      href: '/',
    },
      {
        title: 'Acteurs',
        disabled: true,
        href: 'actors/',
      },],
    loading: true,
    page:1,
    totalActors:0,

    scanner: false
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nouveau Acteur' : 'Modification de l\'acteur'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    page() {
      this.getActors();
    }
  },

  created () {
    // this.initialize()
    this.getActors();
  },

  methods: {
    initialize () {
      this.actors = [
      ]
      this.getActors();
    },

    editItem (item) {
      this.editedIndex = this.actors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true;
      this.addAction = false;
    },

    deleteItem (item) {
      this.editedIndex = this.actors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true;
      this.addAction = false;
    },

    deleteItemConfirm () {
     // this.actors.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false;
      this.addAction = true;
      this.dialogQRcode = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {

      try {
        let data = {
          first_name: this.editedItem.first_name,
          last_name: this.editedItem.last_name,
        }
        if (!this.addAction && this.editedItem) {
          const url = 'actor/' + this.editedItem.id + '/update/';
          await CommonCRUDService.updateData(data, url);
          AlertService.success('Cet acteur a été modifié avec succes');
          await this.getActors();
        } else {
          const url = 'save-actor/';
          await CommonCRUDService.postData(data, url);
          AlertService.success('L\'acteur a été ajoute avec succes');
          await this.getActors();
        }
        this.close();
      } catch (err) {
        AlertService.error(err.message);
      }

    },
    async getActors() {
      try {
        this.loading = true;
        const url = 'actors/?page=' + this.page;
        CommonCRUDService.getData(url).then(
            (response) => {
              this.loading=false;
              if(response && response.data){
                this.actors = response.data.results;
                this.totalActors = response.data.total_pages;
              }else{
                this.actors = [];
              }
            },
            (error) => {
              this.actors = [];
              const errsms = (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();
              this.loading=false;
              AlertService.error(errsms);
            })
      }catch (error) {
        AlertService.error(error.message);
      }
    },

    editQRcode(item) {
      this.dialogQRcode = true;
      this.editedItem = item;
    },

    gotoActor(id) {
      if (id) {
        this.action = false;
        this.$router.push({ path: '/actor/movies/'+ id, params: { id } , replace: true  })
      }
    }
  },
}
</script>
<style></style>
