<template>

  <div class="w100 it-al-col min-h80">
    <div class="w90 m-auto item-ali-center mt-2 mb-0" style="heigth: fit-content">
      <commonBreadcrumbs class="w-100" :items="items" />
    </div>
    <v-divider class="w-100 m-0"></v-divider>

    <div class="min-h-fit w80 m-auto item-ali-center it-al-col mt-0" v-if="actor">
      <div class="w-100 h-fit m-O">
        <v-card-text class="text-center text-h5">
          Films de l'acteur: {{actor.first_name}} {{actor.last_name}}
        </v-card-text>
      </div>
      <v-divider class="w-100 m-0"></v-divider>

      <div class="w-100 m-0 min-h-fit">
        <v-expansion-panels class="m-0 w-100">
          <v-expansion-panel class="marg1">
            <v-expansion-panel-title  collapse-icon="mdi-minus" expand-icon="mdi-account-box">
              <strong class="size12"> Informations de l'acteurs</strong>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <span class="marg1 text-h5"> <strong>Prénom: </strong>{{ actor.first_name}}</span>

              <span class="marg1 text-h5"> <strong>Nom: </strong> {{ actor.last_name}}</span>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel clas="marg1">
            <v-expansion-panel-title disable-icon-rotate>
              <strong class="size12">  Films de l'acteurs</strong>
              <template v-slot:actions>
                <v-icon color="green" icon="mdi-movie-cog">
                </v-icon>
              </template>
            </v-expansion-panel-title>
            <v-card v-if="movies && movies.length === 0">
              cet acteur ne figure dans aucun film
            </v-card>
            <div v-if="movies && movies.length > 0">

              <v-data-table
                  :headers="headers"
                  :items="movies"
                  class="elevation-1"
              >
                <template v-slot:[`item.grade`]="{ item }">

                    <div v-if="item.grade" class="d-flex justify-space-between">
                      <div ><strong>Nombre : </strong>
                        <v-chip v-tooltip="'Nombre de notation du film'"
                            :color="getColor(item.grade)"
                            dark
                        >{{ item.grade.count }} </v-chip></div>
                      <div v-tooltip="'Moyenne de notation du film'"><strong>Moyenne : </strong>
                        <v-chip
                            :color="getColor(item.grade)"
                            dark
                        >{{ item.grade.grade_avg }} /5</v-chip>
                      </div>

                      <v-btn color="orange"  @click="gotoMovie(item.id)" v-tooltip="'Détails du film'">
                        <v-icon>mdi-movie-search</v-icon>
                        Détails
                      </v-btn>

                    </div>

                </template>
              </v-data-table>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>

  </div>
</template>

<script>

  import CommonCRUDService from "@/services/CommonCRUD.service";
  import AlertService from "@/services/alert.service";
  import commonBreadcrumbs from "@/components/commons/commonBreadcrumbs";

  export default {
    name: 'ActorMovies',
    data (){
      return {
        actor: null,
        movies:[{'id': 0, 'title': '', 'grade' : []}],
        headers: [
          {
            title: 'Titre',
            align: 'start',
            sortable: true,
            key: 'title',
          },
          { title: 'Notations', key: 'grade' },
        ],
        items: [ {
          title: 'Accueil',
          disabled: false,
          href: '/',
        },
          {
            title: 'Acteurs',
            disabled: true,
            href: 'actors/',
          },
          {
            title: 'Details',
            disabled: true,
            href: 'actors/',
          }],
      };
    },
    components: {
      commonBreadcrumbs
    },
    created () {
      this.getActorById();
    },
    methods: {
      async getActorById() {
        if (this.$route && this.$route.params) {
          const id = this.$route.params.id;
          const url = `actor/${id}/`
          this.loader = true;
          CommonCRUDService.getData(url).then(
              (response) => {
                this.loader = false;
                if (response && response.data && response.data.actor) {
                  this.actor = response.data.actor;
                  this.movies = response.data.actor.movies;
                  if (this.items.length > 2 && this.items[2] && this.actor){
                    this.items[2].title = "Details de l'acteur : " + this.actor.first_name;
                    this.items = [... this.items];
                  }
                }
              },
              (error) => {
                this.loader = false;
                this.movies = [];
                const errsms = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                AlertService.error(errsms);
              })

        }
      },

      getColor (grade) {
        if (grade && grade.count > 0){
          if (grade.grade_avg === 1 ||grade.grade_avg <1) return 'green'
          else if (grade.grade_avg === 2 ||grade.grade_avg < 2) return 'purple'
          else if (grade.grade_avg === 3 ||grade.grade_avg <3) return 'orange'
          else if (grade.grade_avg === 4 ||grade.grade_avg <4) return 'indigo'
          else return 'red'
        }else {
          return 'gray'
        }

      },
      gotoMovie(id) {
        if (id) {
          this.$router.push({ path: '/movie/'+ id, params: { id: id } , replace: true  })
        }
      }
    }

    
  }
</script>
