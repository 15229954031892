import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/Home"
import Actors from "@/components/users/Actors";
import ManageMovie from "@/components/movies/ManageMovie";
import DescribeMovie from "@/components/movies/DescribeMovie";
import ActorMovies from "@/components/users/ActorMovies";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/actors/',
        name: 'Actors',
        component: Actors
    },
    {
        path: '/movies/',
        name: 'ManageMovie',
        component: ManageMovie
    },
    {
        path:"/movie/:id",
        name: 'DescribeMovie',
        component: DescribeMovie
    },

    {
        path:"/actor/movies/:id",
        name: 'ActorMovies',
        component: ActorMovies
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

