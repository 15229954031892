<template>
  <v-card>
    <v-card-title>
      <span class="text-h5" > {{ (addAction) ? 'Ajout d\'un film' : 'Modification d\'un film'}}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
                label="Nom *"
                required
                v-model="title"
                :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
                label="Description"
                required
                v-model="description"
            ></v-textarea>
          </v-col>


          <v-col>

            <v-card class="w-100 ma-auto">
              <v-label>Acteurs:</v-label>
              <MultiSelect v-model="selectActors" :options="actors" optionLabel="first_name" placeholder="Select actors" display="chip" class="w-full md:w-80" :rules="[required]" filter>
                <template #optiongroup="slotProps">
                  <div class="flex items-center">
                    <div>{{ (slotProps && slotProps.option) ? slotProps.option.first_name: '' }}
                      {{ (slotProps && slotProps.option) ? slotProps.option.last_name: '' }}
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </v-card>

<!--            <v-chip v-for="item in selectActors" :key="item.id">
                  <span>
                    {{(item)? item.first_name: ''}}
                    {{(item)? item.last_name: ''}}
                  </span>
            </v-chip>-->
          </v-col>

        </v-row>
      </v-container>
    </v-card-text>
    <div class="card it-sp-bw it-al-row pa-2">

      <v-btn color="warning cur-p"  v-on:click="close">
        <v-icon>mdi-close-circle</v-icon>
        Annuler
      </v-btn>
      <v-btn color="indigo-darken-3 cur-p" v-on:click="saveMovie()">
        <v-icon>mdi-content-save</v-icon>
        Enregistrer
      </v-btn>
<!--
  <v-btn
          color="warning"
          variant="text"
          v-on:click="close">
        Annuler
      </v-btn>

      <v-btn
          color="success"
          class="mr-4 cur-p"
          variant="text"
          v-on:click="saveMovie()"
      >
        Enregistrer
      </v-btn>-->
    </div>
  </v-card>

</template>
<script>
import CommonCRUDService from "@/services/CommonCRUD.service";
import MultiSelect from "primevue/multiselect";
import AlertService from "@/services/alert.service";

export default {
  components: {
    MultiSelect
  },

  data() {
    return {
      title: "",
      description: "",
      selectActors: [],
      actors: []
    };
  },
  props: ['addAction', 'content', 'movie'],
  emits: ['close', 'action'],

  methods: {
    close() {
      this.initFileld();
      this.$emit('close', true)
    },

    async saveMovie() {
      try {
        let data = {
          title: this.title,
          description: this.description,
          actors: (this.selectActors)? this.selectActors.map(item=> item.id) : [],
        }
        if (this.movie) {
          data.id = this.movie.id
        }
        if (this.addAction) {
          const url = 'save-movie/';
          await CommonCRUDService.postData(data, url);
          AlertService.success('Le film a été ajoute avec succes');
          // this.$emit('action', true);
          this.close();
        } else if(this.movie) {
          const url = 'movie/' + this.movie.id + '/update-movie/';
          await CommonCRUDService.updateData(data, url);
          AlertService.success('Ce film a été modifié avec succes');
        //  this.$emit('action', true)
          this.close();
        }
      } catch (err) {
        console.log(err);
        AlertService.error(err.message);
      }
    },
    async geActors() {
      try {
        const response = await CommonCRUDService.getData('all-actors/');
        if(response && response.data){
          this.actors = structuredClone(response.data);
        }else{
          this.actors = [];
        }
      }catch (error) {
        console.log(error);
        AlertService.error(error.message);
      }
    },
    initFileld() {
      if (this.movie){
        this.title = this.movie.title;
        this.selectActors = this.movie.actors;
        this.description = this.movie.description;
      }
    }

  },

  created() {
    this.geActors();
    this.initFileld()
  },
  computed: {

  },

}
</script>
