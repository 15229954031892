<template>
   <div class="min-h-fit w90 m-auto item-ali-center">
     <Movies class="w-100" />
   </div>
</template>

<script>

  import Movies from "@/components/movies/Movies";

  export default {
    name: 'ManageMovie',
    components: {
      Movies
    }
    
  }
</script>
