<template>
  <v-hover
  v-slot="{hover}"
  open-delay="200"
  >
  <v-card :elevation="hover ? 16:2" :class="{'on-hover' : hover}" class="w-100">
      <router-link :to="`/movie/${movie.id}`">
      <v-img :src="'https://cdn.vuetifyjs.com/images/cards/docks.jpg'" alt="" class=""></v-img>
      </router-link>
      <v-card-title class="subtitle-2" v-tooltip="'Titre du film'">{{movie.title}}</v-card-title>
    <v-divider class="w-100"></v-divider>
      <v-card-text class="it-al-row">

        <v-rating v-model="rating" class="ma-auto" v-tooltip="'Moyenne de notation'">
          <template v-slot:item="props">
            <v-icon
                :color="(props.isFilled && movie && this.movie.grade && this.movie.grade.grade_avg>0) ? colors[props.index] : 'grey-lighten-1'"
                size="large"
                @click="props.onClick"
            >
              {{ props.isFilled ? 'mdi-star-circle' : 'mdi-star-circle-outline' }}
            </v-icon>
          </template>
        </v-rating>
        <div> &nbsp; | &nbsp;</div>
        <v-row align="center" class="mx-0">
          <v-btn color="orange" v-on:click="goToPage(movie)" v-tooltip="'Détails du film'">
            <v-icon>mdi-movie-search</v-icon>
            Détails
          </v-btn>
<!--          <v-card-actions>
            <v-btn color="orange" text="Détails" v-on:click="goToPage(movie)"></v-btn>
          </v-card-actions>-->
        </v-row>
      </v-card-text>
  </v-card>

  </v-hover>
</template>

<script>
export default {
  name: 'MovieBox',
  data: () => ({
    colors: ['green', 'purple', 'orange', 'indigo', 'red'],
  }),
  props: {
    movie: {
      required: true,
    }
  },
  computed: {
    rating() {
      return (this.movie && this.movie.grade)? this.movie.grade.grade_avg: null;
    }
  },
  methods: {
    goToPage(movie) {
      if (movie) {
        this.$router.push({ path: '/movie/'+movie.id, params: { id: movie.id } , replace: true  })
      }
    }
  }
}
</script>

<style>

</style>
