<template>

   <div class="min-h80 w80 m-auto item-ali-center it-al-col">
     <div class="w-100">
       <v-card-text class="text-center text-h5">
         Bien venu sur ce site de gestion de film. A travers ce site vous pouvez: <br/>

        <ul class="text-h6 m-auto">
          <li> Ajouter, modifier et noter un <strong>film</strong> de votre choix</li>
          <li> Ajouter, modifier votre <strong>acteur</strong></li>
        </ul>
       </v-card-text>
     </div>
     <v-divider class="w-100 m-0"></v-divider>

     <Movies class="w-100" :source="'home'"  />
   </div>
</template>

<script>
import Movies from '../components/movies/Movies'

  export default {
    name: 'Home',
    data (){
      return {
      };
    },
    components: {
      Movies
    }
    
  }
</script>
