
import http from "./http-common";
import {API_URL} from "@/config/dev.env";

class CommonCRUDService {

  getData(url) {
    return http.get(API_URL + url);
  }

  postData(data, url) {
    return http.post(API_URL + url, data);

  }

  updateData(data, url) {
    return http.put(API_URL + url, data);

  }
}

export default new CommonCRUDService();
