<template>

  <div class="mx-3 w100">

    <div class="w-100 m-auto item-ali-center" v-if="source !== 'home'">
      <commonBreadcrumbs class="w-100" :items="items" />
    </div>
    <v-divider class="w-100" v-if="source !== 'home'"></v-divider>

    <div class="d-flex justify-space-between" v-if="source !== 'home' && !addMovi">
      <v-col cols="auto">
        <v-btn  color="indigo-darken-3" v-on:click="addMovi=!addMovi">
          <v-icon>mdi-movie-plus</v-icon>
          Ajouter un film</v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn color="indigo-darken-3" v-on:click="action=!action">
            <v-icon>mdi-camera</v-icon>
          Scanner
        </v-btn>
      </v-col>
      <h2 class="mt-2 grey--text" v-if="!addMovi"> Liste des Films</h2>

    </div>

    <v-divider class="w-100" v-if="source !== 'home'"></v-divider>
    <v-card v-if="action" class="w-fit">
      <v-dialog
          v-model="action"
          min-height="180"
          max-width="380">
        <QRCodeReader :source="'movie'" :action="action" @close="action=!action" @movie="gotoMoVie"/>
      </v-dialog>
    </v-card>

    <v-container v-if="!addMovi" class="w-100" fluid>

      <v-row class="d-flex w-100">
        <v-col cols="12" sm="6" md="4" v-for="movie in movies" :key="movie.id" style="min-width: 25vw!important;" >
          <MovieBox class="w-100" :movie="movie"/>
        </v-col>
      </v-row>
      </v-container>
    <div class="text-center" v-if="!addMovi">
      <v-pagination
          v-model="page"
          :length="total"
          @input="geMovies"
          rounded="circle"
      ></v-pagination>
    </div>

    <AddMovie v-if="addMovi" :add-action="true" @close="close" @action="geMovies" />

  </div>
</template>

<script>
import MovieBox from '../../components/movies/MovieBox';
import CommonCRUDService from "@/services/CommonCRUD.service";
import AddMovie from "@/components/movies/AddMovie";
import AlertService from "@/services/alert.service";
import commonBreadcrumbs from "@/components/commons/commonBreadcrumbs";
import QRCodeReader from "@/components/commons/QRCodeReader";

export default {
  name: 'Movies',
  components :{
    MovieBox,
    AddMovie,
    commonBreadcrumbs,
    QRCodeReader
  },
  data: function (){
    return {
      action: false,
      dialogQRcode: false,
      movies: [],
      page: 1,
      pageSize: 7,
      addMovi:false,
      total: 0,
      items: [ {
        title: 'Accueil',
        disabled: false,
        href: '/',
      },
        {
          title: 'Films',
          disabled: true,
          href: 'movies/',
        },],
    };
  },
  props: ['source'],

  async mounted(){
    await this.geMovies();
  },
  methods: {
    async geMovies() {
      try {
        console.log(this.page);
        const url = 'movies/?page=' + this.page;
        CommonCRUDService.getData(url).then(
            (response) => {
              this.addMovi = false;
              if(response && response.data){
                this.movies = response.data.results;
                this.total = response.data.total_pages;
              }
            },
            (error) => {
              this.movies = [];
              const errsms = (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();
              console.log(errsms);
              AlertService.error(error);
            })
      }catch (error) {
        console.log(error);
        AlertService.error(error.message);
      }
    },
    close() {
      this.addMovi = false;
      this.geMovies();
    },
    gotoMoVie(id){
      if (id) {
        this.action = false;
        this.$router.push({ path: '/movie/'+ id, params: { id } , replace: true  })
      }
    }

  },
  watch: {
    page() {
      this.geMovies();
    }
  }
}
</script>

<style>

</style>
